//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import breakpointsListMixin from '~/mixins/breakpointsList.mixin';

export default {
    name: "SizeTablePlusSize",
    mixins: [
        breakpointsListMixin,
    ],
    data: function () {
        return {
            isInch: false,
            tableData: {
                head: {
                    inch:       [this.$t('sizeTable.wTights.collName'), "1X (46-48)", "2X (50-52)", "3X (54-56)", "4X (58-60)"],
                    centimeter: [this.$t('sizeTable.wTights.collName'), "1X (46-48)", "2X (50-52)", "3X (54-56)", "4X (58-60)"]
                },
                body: {
                    inch: [
                        [this.$t('sizeTable.wTights.rows.row1'), "35” - 38 1/2”", "39” - 42 1/2”", "43” - 46 1/2”", "47” - 50 1/2”"],
                        [this.$t('sizeTable.wTights.rows.row2'), "43 1/2” - 47”", "47 1/2” - 51”", "51 1/2” - 55”", "55 1/2” - 59”"],
                        [this.$t('sizeTable.wTights.rows.row3'), "31”", "31”", "30 1/2”", "30”"],
                    ],
                    centimeter: [
                        [this.$t('sizeTable.wTights.rows.row1'), "89 - 98см", "99 - 108см", "109 - 118см", "119 - 128см"],
                        [this.$t('sizeTable.wTights.rows.row2'), "111 - 120см", "121 - 130см", "131 - 140см", "141 - 150см"],
                        [this.$t('sizeTable.wTights.rows.row3'), "78см", "78см", "77см", "76см"],
                    ]
                }
            }
        }
    },

    computed: {
        tableHeadData() {
            return this.isInch ? this.tableData.head.inch : this.tableData.head.centimeter
        },
        tableBodyData() {
            return this.isInch ? this.tableData.body.inch : this.tableData.body.centimeter
        },
        isMobile() {
            return this.mobileDown;
        },
        windowWidth() {
            return process.browser ? document.documentElement.clientWidth : 320;
        },
        isTable() {
            return this.tabletDown;
        },
        maxWidth() {
            return ((this.isMobile || this.isTable) ? (this.windowWidth - 40) : 680) + "px";
        }
    }

}
