//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ResponsiveImage from "../../../../common/ResponsiveImage";
import AnchorsList from "./components/AnchorsList/Index";
import SizeTableStandart from "./components/SizeTable/SizeTableStandart.vue";
import SizeTableInternational from "./components/SizeTable/SizeTableInternational.vue";
import SizeTableTall
    from "~/components/popups/SizeTable/components/SizeWTightsTable/components/SizeTable/SizeTableTall.vue";
import SizeTablePetite
    from "~/components/popups/SizeTable/components/SizeWTightsTable/components/SizeTable/SizeTablePetite.vue";
import SizeTablePlusSize
    from "~/components/popups/SizeTable/components/SizeWTightsTable/components/SizeTable/SizeTablePlusSize.vue";
import SizeTablePlusSizeInter
    from "~/components/popups/SizeTable/components/SizeWTightsTable/components/SizeTable/SizeTablePlusSizeInter.vue";

export default {
    name: "SizeWTights",
    data: function() {
        return {
            bottomImage: 'https://media.adidas.ua/e494e73dbcfe36349c510368d5df7639/5/5705e1164a8394aace6018e27d20d237/64a542a15df9b/df9f.svg',
            infoImage1: 'https://media.adidas.ua/e494e73dbcfe36349c510368d5df7639/5/2cd4e8a2ce081c3d7c32c3cde4312ef7/64a5474fa8efa/8efd.svg',
            infoImage2: 'https://media.adidas.ua/e494e73dbcfe36349c510368d5df7639/5/51174add1c52758f33d414ceaf3fe6ba/64a547626b208/b20a.svg',
            infoImage3: 'https://media.adidas.ua/e494e73dbcfe36349c510368d5df7639/5/c902b497eb972281fb5b4e206db38ee6/64a547753c359/c35b.png',
        }
    },
    computed: {
        imageSrc() {
            return this.isWomenSwimsuits ? this.swimsuitsImage : this.clothesImage;
        },
        l () {
            return this.$i18n.locale
        }
    },
    methods: {
        scrollTo(el) {
            return {
                el: el,
                container: '.v-popup__container__body',
                duration: 300,
                lazy: false,
                easing: 'linear',
                cancelable: true,
                x: false,
                y: true
            }
        },
        imageConfig(url, title = "", alt = "") {
            return {
                src: {
                    desktop: {
                        '1x': url,
                        '2x': url,
                    },
                    tablet: {
                        '1x': url,
                        '2x': url,
                    },
                    mobile: {
                        '1x': url,
                        '2x': url,
                    },
                },
                title: title,
                alt: alt,
            }
        },
    },
    components: {
        SizeTablePlusSizeInter,
        SizeTablePlusSize,
        SizeTablePetite,
        SizeTableTall,
        SizeTableInternational,
        SizeTableStandart,
        AnchorsList,
        ResponsiveImage
    },
}
