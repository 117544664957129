//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import breakpointsListMixin from '~/mixins/breakpointsList.mixin';

export default {
    name: "SizeTableInternational",
    mixins: [
        breakpointsListMixin,
    ],
    data: function () {
        return {
            tableData: {
                head: {
                    centimeter: ["UA", "2XS (26-28)", "XS (30-32)", "S (34-36)", "M (38-40)", "L (42-44)", "XL (46-48)", "XXL (50-52)",]
                },
                body: {
                    centimeter: [
                        ["US", "2XS (00)", "XS (0-2)", "S (4-6)", "M (8-10)", "L (12-14)", "XL (16-18)", "2XL (20)"],
                        ["UK", "2XS (0-2)", "XS (4-6)", "S (8-10)", "M (12-14)", "L (16-18)", "XL (20-22)", "2XL (24-26)"],
                        ["FR", "2XS (28-30)", "XS (32-34)", "S (36-38)", "M (40-42)", "L (44-46)", "XL (48-50)", "2XL (52-54)"],
                        ["IT", "2XS (32-34)", "XS (36-38)", "S (40-42)", "M (44-46)", "L (48-50)", "XL (52-54)", "2XL (56-58)"],
                    ]
                }
            }
        }
    },

    computed: {
        tableHeadData() {
            return this.tableData.head.centimeter
        },
        tableBodyData() {
            return this.tableData.body.centimeter
        },
        isMobile() {
            return this.mobileDown;
        },
        windowWidth() {
            return process.browser ? document.documentElement.clientWidth : 320;
        },
        isTable() {
            return this.tabletDown;
        },
        maxWidth() {
            return ((this.isMobile || this.isTable) ? (this.windowWidth - 40) : 680) + "px";
        }
    }

}
