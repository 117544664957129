//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import breakpointsListMixin from '~/mixins/breakpointsList.mixin';

export default {
    name: "SizeTablePlusSizeInter",
    mixins: [
        breakpointsListMixin,
    ],

    data: function () {
        return {
            tableData: {
                head: {
                    centimeter: ["UA", "1X (46-48)", "2X (50-52)", "3X (54-56)", "4X (58-60)"]
                },
                body: {
                    centimeter: [
                        ["US", "1X (14W - 16W)", "2X (18W - 20W)", "3X (22W - 24W)", "4X (26W - 28W)"],
                        ["UK", "1X (20-22)", "2X (24-26)", "3X (28-30)", "4X (32-34)"],
                        ["FR", "1X (48-50)", "2X (52-54)", "3X (56-58)", "4X (60-62)"],
                        ["IT", "1X (48-50)", "2X (56-58)", "3X (60-62)", "4X (64-66)"],
                    ]
                }
            }
        }
    },

    computed: {
        tableHeadData() {
            return this.tableData.head.centimeter
        },
        tableBodyData() {
            return this.tableData.body.centimeter
        },
        isMobile() {
            return this.mobileDown;
        },
        windowWidth() {
            return process.browser ? document.documentElement.clientWidth : 320;
        },
        isTable() {
            return this.tabletDown;
        },
        maxWidth() {
            return ((this.isMobile || this.isTable) ? (this.windowWidth - 40) : 680) + "px";
        }
    }

}
