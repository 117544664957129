//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import breakpointsListMixin from '~/mixins/breakpointsList.mixin';

export default {
    name: "SizeTablePetite",
    mixins: [
        breakpointsListMixin,
    ],

    data: function () {
        return {
            isInch: false,
            tableData: {
                head: {
                    inch:       [this.$t('sizeTable.wTights.collName'), "2XS Petite", "XS Petite", "S Petite", "M Petite", "L Petite", "XL Petite", "2XL Petite"],
                    centimeter: [this.$t('sizeTable.wTights.collName'), "2XS Petite", "XS Petite", "S Petite", "M Petite", "L Petite", "XL Petite", "2XL Petite"]
                },
                body: {
                    inch: [
                        [this.$t('sizeTable.wTights.rows.row1'), "22 - 23.5”", "24 - 26”", "26.5 - 28.5”", "29 - 31”", "31.5 - 33.5”", "34 - 37”", "37.5 - 41”"],
                        [this.$t('sizeTable.wTights.rows.row2'), "31.5 - 33”", "33.5 - 35.5”", "36 - 38”", "38.5 - 40.5”", "41 - 43”", "43.5 - 46”", "46.5 - 49”"],
                        [this.$t('sizeTable.wTights.rows.row3'), "28.5”", "29”", "29”", "29.5”", "29.5”", "29.5”", "30”"],
                    ],
                    centimeter: [
                        [this.$t('sizeTable.wTights.rows.row1'), "57 - 60см", "61 - 66см", "67 - 72см", "73 - 78см", "79 - 85см", "86 - 94см", "95 - 104см"],
                        [this.$t('sizeTable.wTights.rows.row2'), "82 - 85см", "86 - 91см", "92 - 97см", "98 - 103см", "104 - 110см", "111 - 117см", "118 - 125см"],
                        [this.$t('sizeTable.wTights.rows.row3'), "72.5см", "73см", "73.5см", "74см", "74.5cm", "75cm", "75.5см"],
                    ]
                }
            }
        }
    },

    computed: {
        tableHeadData() {
            return this.isInch ? this.tableData.head.inch : this.tableData.head.centimeter
        },
        tableBodyData() {
            return this.isInch ? this.tableData.body.inch : this.tableData.body.centimeter
        },
        isMobile() {
            return this.mobileDown;
        },
        windowWidth() {
            return process.browser ? document.documentElement.clientWidth : 320;
        },
        isTable() {
            return this.tabletDown;
        },
        maxWidth() {
            return ((this.isMobile || this.isTable) ? (this.windowWidth - 40) : 680) + "px";
        }
    }

}
